import React from "react";

import Context from "./../../Context";

export default () => {
  return (
    <Context.Consumer>
      {({ display }) => (
        <div className={"branding"}>
          <span className="pull pull-left">
            <a href="https://navigator.bytebeacon.com" className="">
              {"\u26A1  "} by PageNav
            </a>
          </span>
          <span className="pull pull-right">
            <kbd className="">ESC</kbd> to exit
          </span>
        </div>
      )}
    </Context.Consumer>
  );
};
