import React from "react";

import Context from "../../Context";
import { STORAGE_KEY } from "../../constants";
import { setToStorage } from "../../common/utils/storage";

export default class App extends React.Component {
  render() {
    return (
      <Context.Consumer>
        {({ isOpen, close }) => <>{isOpen ? <NL1 close={close} /> : ""}</>}
      </Context.Consumer>
    );
  }
}

class NL1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }
  onChange(e) {
    const state = this.state;
    state.email = e.target.value;
    this.setState(state);
  }
  // const [email, setEmail] = useState("");

  onSubscribe(e) {
    e.preventDefault();

    if (!this.state.email) {
      document.getElementById("subscribe_error").style.display = "block";
      document.getElementById("subscribe_error").innerText =
        "Please enter a valid email address!";
      return;
    }

    document.getElementById("subscribe_btn").disabled = true;
    document.getElementById("subscribe_loader").style.display = "block";
    document.getElementById("subscribe_error").style.display = "none";
    document.getElementById("subscribe_success").style.display = "none";

    const data = { email: this.state.email };

    function triggerSuccessfulSubmission(close) {
      document.getElementById("subscribe_btn").disabled = false;
      document.getElementById("subscribe_loader").style.display = "none";

      // # success
      document.getElementById("subscribe_success").style.display = "block";
      document.getElementById("subscribe_success").innerText =
        "👍 Submitted successfully!";
      document.getElementById("subscribe_form").style.display = "none";

      setToStorage(STORAGE_KEY.LAST_SUBMITTED, Date.now());

      setTimeout(close, 3000);
    }

    function triggerFailedSubmission(close) {
      document.getElementById("subscribe_btn").disabled = false;
      document.getElementById("subscribe_loader").style.display = "none";

      // # error
      document.getElementById("subscribe_error").style.display = "block";
      document.getElementById("subscribe_error").innerText =
        "😞 Unable to subscribe!";
    }

    // setTimeout(triggerSuccessfulSubmission, 3000, this.props.close);

    // REACT_APP_SUBSCRIPTION_API_DOMAIN=https://divshow-api.bytebeacon.com
    fetch("https://divshow-api.bytebeacon.com/api/v1/public/subscribe", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        const state = this.state;
        state.email = "";
        this.setState(state);

        triggerSuccessfulSubmission(this.props.close);
      })
      .catch((error) => {
        triggerFailedSubmission(this.props.close);
      });
  }

  onSubscribeComplete() {
    this.props.close();
  }

  render() {
    const descriptions = [
      "Subscribe to our newsletter and stay up-to-date with new collections and exclusive offers.",
      "Stay in the know about our latest collections and exclusive deals by subscribing to our newsletter.",
      "Sign up for our newsletter to keep yourself updated on new arrivals and special offers.",
      "Join our newsletter to stay informed about new collections and receive exclusive promotions.",
      "Get the latest updates on our collections and access exclusive offers when you subscribe to our newsletter.",
      "Never miss out on our new arrivals and exclusive discounts - subscribe to our newsletter today.",
      "Stay up-to-date with our newest collections and exclusive deals by subscribing to our newsletter.",
      "By subscribing to our newsletter, you'll be the first to know about our latest collections and special offers.",
      "Don't miss a beat when it comes to new collections and exclusive promotions - subscribe to our newsletter now.",
      "Join our newsletter and stay in the loop with new arrivals and members-only discounts.",
      "Receive updates on our fresh collections and exclusive offers by becoming a newsletter subscriber.",
    ];

    const titles = [
      "Before you go!",
      "Don't Go Yet!",
      "Wait! Before You Leave ...",
      "Hang On! Explore Exclusive News & Offers",
      "Hold Up!",
      "You Don't Want to Miss Out",
      "Before You Leave ...",
      "Be the First to Know Before You Go",
      "Don't Leave Just Yet - Stay Informed",
      "Benefits Await You",
      "Before You Depart ...",
    ];

    var randomIndexDesc = Math.floor(Math.random() * descriptions.length);
    var randomIndexTitle = Math.floor(Math.random() * titles.length);

    const form_data = {
      title: titles[randomIndexTitle],
      description: descriptions[randomIndexDesc],
    };

    return (
      <div id="subscription_form">
        <div className="overlay" onClick={this.props.close}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <a className="close" href="#" onClick={this.props.close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </a>

            <div className="box">
              <div className="title box-item">{form_data.title}</div>
              <div className="description box-item">
                {form_data.description}
              </div>
              <div id="subscribe_loader" className=" box-item"></div>
              <div
                id="subscribe_success"
                className="notification success box-item"
              >
                Submitted successfully! 👍
              </div>
              <div id="subscribe_error" className="notification error box-item">
                😞 Unable to subscribe!
              </div>
              <form id="subscribe_form" className=" box-item">
                <input
                  onChange={this.onChange.bind(this)}
                  placeholder="example@example.com"
                  type="text"
                  value={this.state.email}
                  name="subscribe_email"
                />
                <button
                  id="subscribe_btn"
                  className="button"
                  onClick={this.onSubscribe.bind(this)}
                >
                  {"Join 📩 "}
                </button>{" "}
              </form>
            </div>

            <div className="footer">
              <div className="section">
                <kbd className="">ESC</kbd>
                &nbsp;{"to exit"}
              </div>

              <div className="section">
                <a
                  href="https://supacta.bytebeacon.com"
                  target="_blank"
                  className="section "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  &nbsp;{"by SupaCTA"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
