import React from "react";

import Context from "./../../Context";

export default class Backdrop extends React.Component {
  render() {
    return (
      <Context.Consumer>
        {({ isOpen, close }) => (
          <div className={"" + (isOpen ? "backdrop" : "")} onClick={close} />
        )}
      </Context.Consumer>
    );
  }
}
