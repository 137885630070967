import React, { Component } from "react";

import { NAMESPACE } from "./constants";
import Context from "Context";
import { useHotkeys } from "react-hotkeys-hook";

import Panel from "panel";
import Trigger from "trigger";

import {
  getFromStorage,
  setToStorage,
  incrementStorage,
} from "./common/utils/storage";
import { STORAGE_KEY } from "./constants";
import { console_log } from "./common/utils/logs";

const DEFAULT_STATE = {
  isOpen: false,
};

export default class PanelTrigger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...DEFAULT_STATE,
      options: this.props.options,

      toggle: () => {
        this.setState({ isOpen: !this.state.isOpen });
        const key = this.state.isOpen
          ? STORAGE_KEY.COUNT_CLOSE
          : STORAGE_KEY.COUNT_OPEN;
        incrementStorage(key);
      },
      close: () => {
        if (this.state.isOpen) {
          incrementStorage(STORAGE_KEY.COUNT_CLOSE);
        }
        this.setState({ isOpen: false });
      },
      open: () => {
        if (!this.state.isOpen) {
          incrementStorage(STORAGE_KEY.COUNT_OPEN);
        }
        this.setState({ isOpen: true });
      },
    };
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  close = () => {
    if (this.state.isOpen) {
      incrementStorage(STORAGE_KEY.COUNT_CLOSE);
      const state = this.state;
      state.isOpen = false;
      this.setState(state);
    }
  };

  open = () => {
    if (!this.state.isOpen) {
      incrementStorage(STORAGE_KEY.COUNT_OPEN);
      const state = this.state;
      state.isOpen = true;
      this.setState(state);
    }
  };

  _listenKey = (event) => {
    //   const isTrigger = event.ctrlKey && event.keyCode === 32;
    //   if (!isTrigger) {return;}
    //   this.state.toggle();

    const shouldClose = event.keyCode === 27 && this.state.isOpen;
    if (!shouldClose) {
      return;
    }
    this.state.close();
  };

  _close = (event) => {
    if (!this.state.isOpen) {
      if (
        document
          .getElementById(this.props.options.widget_id)
          .contains(event.target)
      ) {
        // Clicked in the panel box .. do nothing
      } else {
        // Clicked outside the panel box
        this.state.close();
      }
    }
  };

  _check_bounds = (event) => {
    if (
      event.clientY <= 0 ||
      event.clientX <= 0 ||
      event.clientX >= window.innerWidth ||
      event.clientY >= window.innerHeight
    ) {
      // Check submission
      const last_submitted = getFromStorage(STORAGE_KEY.LAST_SUBMITTED);
      if (last_submitted) {
        console_log(
          this.props.options,
          `Submitted @ ${last_submitted} .. No need to open!`
        );
        return;
      }

      // TODO: move to config
      // Check time lapse
      const last_bounds_exit = getFromStorage(STORAGE_KEY.LAST_BOUNDS_EXIT);
      const time_to_reask = this.props.options.data.time_to_reask * 1000;
      const lapse_time_reask = Date.now() - last_bounds_exit;
      if (lapse_time_reask < time_to_reask) {
        console_log(
          this.props.options,
          `${lapse_time_reask} seconds completed from ${time_to_reask} cap`
        );
        return;
      }

      if (!this.state.isOpen) {
        this.state.open();
      }

      setToStorage(STORAGE_KEY.LAST_BOUNDS_EXIT, Date.now());
    }
  };

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this._listenKey);
    document.body.removeEventListener("click", this._close);
  }

  async componentDidMount() {
    document.body.addEventListener("keydown", this._listenKey);
    document.body.addEventListener("click", this._close);

    document.body.addEventListener("mouseleave", this._check_bounds);
  }

  render() {
    const options = this.props.options;
    const trigger_key = this.props.options.trigger.key;

    return (
      <div
        id={options.widget_id + "-app"}
        className={options.display.theme + "-bg"}
      >
        <Context.Provider value={this.state}>
          <Hotkey trigger_key={trigger_key} trigger={this.toggle.bind(this)} />
          {/* <Trigger /> */}
          <Panel />
        </Context.Provider>
      </div>
    );
  }
}

function Hotkey(props) {
  useHotkeys(props.trigger_key, () => props.trigger());
  return "";
}
