import React, { Component } from "react";

import { NAMESPACE, POSITION } from "./../constants";
import Context from "Context";

import ButtonIcon from './ButtonIcon';
import FooterText from './FooterText';


export default class Trigger extends Component {

  render() {
    return (
        <Context.Consumer>
          {({ options }) => (
            (options.display.position === POSITION.NONE)? (''): (
              <div id={NAMESPACE+"-trigger-div"} className={options.display.theme+"-bg"}>
                <ButtonIcon />
                <FooterText />
              </div>
            )
          )}
      </Context.Consumer>
    );
  }
}
