import React from "react";
import ReactDOM from "react-dom";

import { NAMESPACE, DEFAULT_CONFIG, STORAGE_KEY } from "./constants";
import { console_log, console_log_group } from "./common/utils/logs";

import PanelTrigger from "./PanelTrigger";

import "./index.scss";
// import { removeFromStorage, setToStorage } from "./common/utils/storage";

function createWidget(input_options) {
  var div_widget = document.createElement("div");
  div_widget.id = NAMESPACE + "-" + new Date().getTime();
  div_widget.className = NAMESPACE;

  let options_display = Object.assign(
    {},
    DEFAULT_CONFIG.display,
    input_options.display
  );

  let derived_options = Object.assign({}, DEFAULT_CONFIG, input_options);
  derived_options.display = options_display;
  derived_options.widget_id = div_widget.id;

  const console_logs = [
    ["⤙ Default", DEFAULT_CONFIG],
    ["⤙ Derived", derived_options],
  ];

  console_log_group(
    derived_options,
    "Derived options while APP init",
    console_logs
  );

  const headCSS =
    document.getElementsByTagName("html")[0].getAttribute("class") || "";
  const computedClass =
    " " + NAMESPACE + "-" + derived_options.display.theme + " ";

  document
    .getElementsByTagName("html")[0]
    .setAttribute("class", headCSS + computedClass);

  document.body.appendChild(div_widget);

  // Object.keys(STORAGE_KEY).map((key, idx) => {
  //   console_log(derived_options, "Removing from storage: " + key);
  //   // removeFromStorage(key);
  //   setToStorage(key, 0);
  // });

  return ReactDOM.render(
    <PanelTrigger
      options={derived_options}
      ref={(widget_supacta) => {
        window.widget_supacta = widget_supacta;
      }}
    />,
    document.getElementById(div_widget.id)
  );
}

export { createWidget };
