import React, { Component } from "react";

import { NAMESPACE } from "./../constants";
import Context from "Context";

import DrawerPanel from "panel/Drawer";
import ModalPanel from "panel/Modal";

export default class Panel extends Component {
  render() {
    return (
      <Context.Consumer>
        {({ options }) => (
          <div
            id={NAMESPACE + "-panel-div"}
            className={options.display.theme + "-bg"}
          >
            <ModalPanel />
            {/* <DrawerPanel /> */}
            {/* <SpotlightPanel /> */}
            {/* <Spotlight 
                  options={derived_options} 
                  api_key={derived_options.api_key} 
                  display={derived_options.display} 
                  data={derived_options.data} 
                  ref={(widget_skeleton) => {window.widget_skeleton = widget_skeleton}} 
                />, */}
          </div>
        )}
      </Context.Consumer>
    );
  }
}
