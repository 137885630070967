import React from "react";

import Context from "../../Context";

import SlideDrawer from "./SlideDrawer";
import Backdrop from "./Backdrop";

export default class App extends React.Component {
  render() {
    return (
      <Context.Consumer>
        {({ isOpen }) => (
          <div>
            <SlideDrawer />
            <Backdrop />
          </div>
        )}
      </Context.Consumer>
    );
  }
}
