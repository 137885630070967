import { NAMESPACE } from "../../constants";

////////////////////////////////////////////////
// LOGS
////////////////////////////////////////////////
export function console_log_group(options, group, line_list) {
  if (!options.debug.console) {
    return;
  }

  console.groupCollapsed(NAMESPACE + ": " + group);
  for (var idx in line_list) {
    console.log(line_list[idx]);
  }
  console.groupEnd();
}

export function console_log(options, line) {
  if (!options.debug.console) {
    return;
  }
  console.log(line);
}
