import React from "react";

import Context from "./../../Context";

export default class SlideDrawer extends React.Component {
  render() {
    return (
      <Context.Consumer>
        {({ isOpen }) => (
          <div className="app">
            <h5 style={{ margin: "0", marginTop: "10px", marginBottom: "4px" }}>
              Was this page helpful?
            </h5>
            <div className="container">
              <div className="item">
                <label htmlFor="0">
                  <input
                    className="radio"
                    type="radio"
                    name="feedback"
                    id="0"
                    value="0"
                  />
                  <span>😡</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="1">
                  <input
                    className="radio"
                    type="radio"
                    name="feedback"
                    id="1"
                    value="1"
                  />
                  <span>🙁</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="2">
                  <input
                    className="radio"
                    type="radio"
                    name="feedback"
                    id="2"
                    value="2"
                  />
                  <span>😐</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="3">
                  <input
                    className="radio"
                    type="radio"
                    name="feedback"
                    id="3"
                    value="3"
                  />
                  <span>😁</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="4">
                  <input
                    className="radio"
                    type="radio"
                    name="feedback"
                    id="4"
                    value="4"
                  />
                  <span>😍</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </Context.Consumer>
    );
  }
}
