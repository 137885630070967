import React from 'react';

import Context from "Context";
import {TRIGGER} from './../../constants';

import './index.scss';


export default function App() {
    const {options, open} = React.useContext(Context);
    if (options.display.trigger !== TRIGGER.FOOTER_TEXT){return '';}
    
    const offset = options.display.offset;
    var style = {}
    style['left'] = offset + "%";
    return (
        <div className="footer-trigger" style={style} onClick={open} >
            <a className={""+options.display.theme+"-text"} >
            <img src="https://bytebeacon.com/static/images/pageworks/logo_animate.svg" />  
                Navigate with <kbd className="">ctrl + k</kbd>
            </a>
        </div>
    );
}
