const NAMESPACE_KEY = "SUPACTA_";

export function getFromStorage(key) {
  return localStorage.getItem(NAMESPACE_KEY + key);
}

export function setToStorage(key, data) {
  localStorage.setItem(NAMESPACE_KEY + key, data);
}

export function incrementStorage(key) {
  var val = getFromStorage(key);
  if (val === null || val === undefined) {
    val = 0;
  }
  val++;
  setToStorage(key, val);
}

export function removeFromStorage(key) {
  localStorage.removeItem(NAMESPACE_KEY + key);
}

// export function hasStoredToken() {
//   return !!_getFromStorage("token");
// }

// export function getStoredToken() {
//   return _getFromStorage("token");
// }

// export function saveToken(token) {
//   _setToStorage("token", token);
// }

// export function deleteToken() {
//   _removeFromStorage("token");
//   clearLoginMeta();
// }
