import React, { useState } from "react";

import Context from "Context";
import { NAMESPACE, TRIGGER } from "./../../constants";

export default function App() {
  const { options, open } = React.useContext(Context);
  if (options.display.trigger !== TRIGGER.BUTTON_ICON) {
    return "";
  }

  const trigger_key = options.trigger.key;
  const keys = trigger_key.split(",");
  if (keys === undefined || keys === null) {
    console.log(NAMESPACE + ": trigger invalid! -- " + trigger_key);
    throw NAMESPACE + ": trigger invalid! -- " + trigger_key;
  }
  const buttonText = keys[0].replace("+", " + ");

  // var buttonText = 'ctrl + space';
  // var buttonText = 'ctrl + k';
  // var buttonText = 'shift + k';
  // var buttonText = '⇧ + k';
  // var buttonText = 'alt + k';
  // var buttonText = '⌥ + k';
  // var buttonText = 'cmd + k';
  // var buttonText = '⌘ + k';

  const style_key = {
    top: ["left", [5, 85]],
    bottom: ["left", [5, 85]],
    right: ["top", [5, 75]],
    left: ["top", [5, 75]],
  }[options.display.position];

  const style_name = style_key[0];
  const computedClass =
    "button-icon-btn button-icon-" + options.display.position;

  const offset = options.display.offset;

  var buttonIconStyle = {};
  buttonIconStyle["width"] = "40px";
  buttonIconStyle["height"] = "40px";
  buttonIconStyle[style_name] = offset + "%";

  var buttonIconHoverStyle = {};
  buttonIconHoverStyle["width"] = "55px";
  buttonIconHoverStyle["height"] = "55px";
  buttonIconHoverStyle[style_name] = offset + "%";

  var textStyle = {};
  textStyle["textAlign"] = "center";
  textStyle["marginTop"] = "2px";
  textStyle["marginBottom"] = "2px";

  var imgStyle = {};
  imgStyle["height"] = "33px";
  var imgHoverStyle = {};
  imgHoverStyle["height"] = "33px";

  if (buttonText.length >= 10) {
    imgHoverStyle["marginTop"] = "-4px";
  }

  const [storedStyle, setStyle] = useState({
    buttonIcon: buttonIconStyle,
    text: textStyle,
    img: imgStyle,
  });
  const style = storedStyle;
  return (
    <div
      onMouseEnter={() =>
        setStyle({
          buttonIcon: buttonIconHoverStyle,
          text: textStyle,
          img: imgHoverStyle,
        })
      }
      onMouseLeave={() =>
        setStyle({
          buttonIcon: buttonIconStyle,
          text: textStyle,
          img: imgStyle,
        })
      }
      className={computedClass}
      style={style.buttonIcon}
      onClick={open}
    >
      <img
        className="button-icon-img"
        style={style.img}
        src="https://bytebeacon.com/static/images/pageworks/logo_animate.svg"
      />
      <kbd className="button-icon-txt" style={style.text}>
        {buttonText}
      </kbd>
    </div>
  );
}
