export const STORAGE_KEY = {
  COUNT_OPEN: "COUNT_OPEN",
  COUNT_CLOSE: "COUNT_CLOSE",
  LAST_BOUNDS_EXIT: "LAST_BOUNDS_EXIT",
  LAST_SUBMITTED: "LAST_SUBMITTED",
};

export const TRIGGER = {
  BUTTON_ICON: "button_icon",
  FOOTER_TEXT: "footer_text",
};

export const THEME = {
  DARK: "dark",
  LIGHT: "light",
};

export const POSITION = {
  NONE: "none",
  RIGHT: "right",
  LEFT: "left",
  TOP: "top",
  BOTTOM: "bottom",
};

////////////////////////////////////////
// Configs to change
////////////////////////////////////////

export const NAMESPACE = "widgetskeleton"; // #TODO: when changing .. grep the code for `widgetskeleton`

export const DEFAULT_CONFIG = {
  api_key: null,

  display: {
    // theme: THEME.DARK,
    theme: THEME.LIGHT,

    offset: "80",
    // position: POSITION.RIGHT,
    // position: POSITION.LEFT,
    // position: POSITION.TOP,
    position: POSITION.BOTTOM,
    // position: POSITION.NONE,

    trigger: TRIGGER.BUTTON_ICON,
    // trigger: TRIGGER.FOOTER_TEXT,
  },

  trigger: {
    // key: 'ctrl+k,ctrl+/',
    // key: "ctrl+k",
    // key: "/",
  },

  debug: {
    // console: true,
    console: false,
  },

  // data: null,
  data: { time_to_reask: 30 },
};
