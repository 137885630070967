import React from "react";

import Context from "./../../Context";

import Branding from "./Branding";
import FeedbackForm from "./FeedbackForm";

export default class SlideDrawer extends React.Component {
  render() {
    return (
      <Context.Consumer>
        {({ isOpen }) => (
          <div className={"side-drawer " + (isOpen ? " open" : " ")}>
            <h3
              style={{
                backgroundColor: "#999999",
                margin: "0",
                padding: "5px",
              }}
            >
              {window.location.hostname}'s control panel
            </h3>

            <FeedbackForm />

            <hr className="" />

            <div className="">Subscribe</div>
            <p className="">
              Subscribe to the Smart Surgery blog and download our white paper
              on adverse events reporting.
            </p>
            <p className="">
              Join our worldwide community of healthcare experts and receive
              weekly insights, research, and tips in your inbox.
            </p>

            <Branding />
          </div>
        )}
      </Context.Consumer>
    );
  }
}
